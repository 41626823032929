import React from 'react'
import styled from 'styled-components'

export const Info = ({ title, copy, reverse }) => {
  return (
    <StyledInfo reverse={reverse}>
      {title}
      {copy}
    </StyledInfo>
  )
}

const StyledInfo = styled.section`
  grid-column: 2 / 3;
  padding: 2rem;
  display: flex;
  flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
  justify-content: center;
  align-items: center;

  @media (max-width: 888px) {
    flex-direction: column;
    padding: 0.2rem;
  }

  h2 {
    padding: 1rem;
    display: flex;
    flex-direction: column;
  }

  p {
    padding: 2rem;
    background-color: var(--secondary);
    border-radius: 5px;
    margin: 1rem;
  }
`
