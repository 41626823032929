import React from 'react'
import Layout from '../Layout'
import About from '../components/about/index'
import SEO from '../components/seo'

const AboutPage = () => {
  return (
    <Layout>
      <SEO
        title="M&S Reliable Construction | Construction company specializing in home building solutions in the lower mainland of Vancouver, British Columbia."
        description="Renovation Services provided by M&S Reliable Construction in British Columbia."
        lang="en"
      />
      <About />
    </Layout>
  )
}

export default AboutPage
