import React from 'react'
import { CarouselProvider, Slide, Slider } from 'pure-react-carousel'
import styled from 'styled-components'
import 'pure-react-carousel/dist/react-carousel.es.css'

export const Testimonials = () => {
  return (
    <StyledCarousel>
      <CarouselProvider totalSlides={3} isPlaying infinite isIntrinsicHeight={true}>
        <Slider className="slider">
          <SlideComponent
            index={0}
            quote={'Great group to do business with! They were always smiling and in a bright mood, rain or shine.'}
            by="Tamara H"
          />
          <SlideComponent
            index={1}
            quote={
              'Mahdi went about and beyond for my renovations. I loved the work they did on my bathroom. Stunning service - highly recommend!'
            }
            by="Robert U"
          />
          <SlideComponent
            index={2}
            quote={
              'M&S put in extra work at the end of our contract. Will be working with them sometime soon again. Appreciate the value they provided. Best in Vancouver.'
            }
            by="Purab V"
          />
        </Slider>
      </CarouselProvider>
    </StyledCarousel>
  )
}

const SlideComponent = ({ index, quote, by }) => {
  return (
    <Slide index={index}>
      <div className="slide">
        <p>"{quote}"</p>
        <p>- {by}</p>
      </div>
    </Slide>
  )
}

const StyledCarousel = styled.section`
  grid-column: 1 / 4;
  width: 100%;
  background-color: var(--brand);

  .carousel__inner-slide {
    display: flex;
    justify-content: center;
  }

  .slider {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    @media (max-width: 750px) {
      max-width: 100%;
    }
  }

  .slide {
    padding: 4rem;
    color: var(--primary);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 750px) {
      max-width: 320px;
      padding: 0.5rem;
    }

    p {
      color: var(--primary);
    }
  }
`
