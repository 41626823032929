import React, { FC, useState } from 'react'
import { Landing } from '../landing'
import { Testimonials } from '../testimonials'
import { useStaticQuery, graphql } from 'gatsby'
import { Split } from '../split'
import { Shifter } from '../shifter'
import { Info } from '../info'
import { CTA } from '../CTA'

const About: FC = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "Home_Renovations_(Unsplash).jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <>
      <Landing
        copy={
          <h1>
            We <br /> Are <br />
            Reliable
          </h1>
        }
        data={data?.placeholderImage?.childImageSharp?.fluid}
      />
      <Info
        title={
          <h2>
            Founders Mahdi &<br />
            Sam
            <br />
          </h2>
        }
        copy={
          <p>
            After many years of experience in the construction industry, we have opened our own renovation company as we
            are extremely passionate about delivering the highest quality remodels, additions, and custom builds on-time
            and on-budget. We have helped countless local homeowners build and remodel their ideal homes based on what
            they’ve imagined. We guarantee every client's satisfaction with the final product by using requirements
            developed in collaboration with them and engaging with them explicitly and honestly during the renovation
            process.
          </p>
        }
      />
      <Info
        title={<h2>At M&S Reliable Construction, We’re Satisfied When Our Clients See Their Dreams Come True.</h2>}
        copy={
          <p>
            Need to improve the visual aesthetic and property value? Don’t worry; M&S Reliable Construction is here to
            offer the best renovation/construction services. Whether you require complete or customized services for
            your home renovation, kitchen renovation, and bathroom renovation, the EA Renovations team will do that for
            you.
          </p>
        }
        reverse
      />
      <Testimonials />

      {/* <Split
        data={data}
        shifter
        copy={
          <div>
            <h2>Renovation Services</h2>
            <Shifter service="Flooring" setShiftState={setShiftState} />
            <Shifter service="Tiling" setShiftState={setShiftState} />
            <Shifter service="Painting" setShiftState={setShiftState} />
            <Shifter service="Drywall" setShiftState={setShiftState} />
            <Shifter service="Mudding" setShiftState={setShiftState} />
          </div>
        }
        shiftState={shiftState}
      /> */}
    </>
  )
}

export default About
